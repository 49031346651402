import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import Header from 'components/Header/Header';
import About from 'components/About/About';
import Offer from 'components/Offer/Offer';
import Certificates from 'components/Certificates/Certificates';
import WhySAP from 'components/WhySAP/WhySAP';
import Competences from 'components/Competences/Competences';
import Contact from 'components/Contact/Contact';
import Career from 'components/Career/Career';
import OurTeam from 'components/OurTeam/OurTeam';

import { teamMembersEN } from 'assets/data/team';
import slugify from 'slugify';

const IndexPage = ({ data }) => {
  const heroImg = data.heroImg.childImageSharp.fluid;
  const card1Img = data.card1Img.childImageSharp.fluid;
  const card2Img = data.card2Img.childImageSharp.fluid;
  const card3Img = data.card3Img.childImageSharp.fluid;
  const certificateImg = data.certificateImg.childImageSharp.fluid;
  const certificatePic = data.certificatePicImg.childImageSharp.fluid;
  const certificatePrince = data.certificatePrinceImg.childImageSharp.fluid;
  const jobOffers = data.allWpPost.edges;

  const slides = [
    `<b>SAP</b> als die Entwicklungsstrategie`,
    `<b>Aseti</b> - professionell im Business, effektiv im Einsatz`,
    `<b>Aseti</b> - die Kraft der Erfahrung, breite Kompetenzen`,
    `<b>Aseti</b> - höheres Businessniveau`,
  ];

  const cards = [
    {
      image: card1Img,
      imageAlt: 'card',
      description:
        'Langjährige Erfahrung bei der SAP ERP Implementierung für verschiedene Branchen',
    },
    {
      image: card3Img,
      imageAlt: 'card',
      description: 'Teamfähigkeit',
    },
    {
      image: card2Img,
      imageAlt: 'card',
      description: 'Klare und verständliche Kommunikation mit dem Business',
    },
  ];

  const offerCards = [
    {
      icon: data.userIcon.publicURL,
      iconAlt: 'user icon',
      description: 'Implementierung und Rollout',
    },
    {
      icon: data.likeIcon.publicURL,
      iconAlt: 'like icon',
      description: 'Integration mit der Anwendung der SAP PI - Lösung',
    },
    {
      icon: data.groupIcon.publicURL,
      iconAlt: 'group icon',
      description: 'Migration auf neue Hardware- und Datenbankplattformen',
    },
    {
      icon: data.internetIcon.publicURL,
      iconAlt: 'internet icon',
      description:
        'SAP - Integration mir anderen Systemen <span>(Webservice, idoc, EDI)</span>',
    },
    {
      icon: data.workIcon.publicURL,
      iconAlt: 'work icon',
      description: 'Audit von SAP-Implementierungen',
    },
    {
      icon: data.clockIcon.publicURL,
      iconAlt: 'clock icon',
      description: 'SAP - Anwedungsservice',
    },
    {
      icon: data.laptopIcon.publicURL,
      iconAlt: 'laptop icon',
      description: 'SAP - Upgrade',
    },
    {
      icon: data.serverIcon.publicURL,
      iconAlt: 'server icon',
      description: 'Verwaltung – SAP-Basis',
    },
  ];

  const competencesCards = [
    {
      icon: data.competence3Icon.publicURL,
      iconAlt: 'icon',
      description: 'Finanzbuchhaltung <span>(SAP FI)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Anlagebuchhaltung <span>(SAP FI -AA)</span>',
    },
    {
      icon: data.competence1Icon.publicURL,
      iconAlt: 'icon',
      description: 'Kassenführung <span>(SAP FI-TR)</span>',
    },
    {
      icon: data.competence5Icon.publicURL,
      iconAlt: 'icon',
      description: 'Kontrolle <span>(SAP CO)</span>',
    },
    {
      icon: data.competence4Icon.publicURL,
      iconAlt: 'icon',
      description: 'Verwaltung von Investitionen <span>(SAP IM)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Projektleitung <span>(SAP PS)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Materialwirtschaft, Beschaffung <span>(SAP MM)</span>',
    },
    {
      icon: data.competence1Icon.publicURL,
      iconAlt: 'icon',
      description:
        'Lagerverwaltung, Lagerverwaltung <span>(SAP MM, SAP WMS)</span>',
    },
    {
      icon: data.competence5Icon.publicURL,
      iconAlt: 'icon',
      description: 'Verkauf und Vertrieb <span>(SAP SD)</span>',
    },
    {
      icon: data.competence4Icon.publicURL,
      iconAlt: 'icon',
      description: 'Planung der Produktion <span>(SAP PP)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Qualitätsmanagement <span>(SAP QM)</span>',
    },
    {
      icon: data.competence3Icon.publicURL,
      iconAlt: 'icon',
      description: 'Wartung <span>(SAP PM)</span>',
    },
  ];

  const certSections = [
    {
      section: 'Infrastructure & Administration',
      certificates: [
        {
          name: `SAP Certified Technology Consultant SAP NetWeaver '04 - SAP Web AS for Oracle`,
          image: certificateImg,
        },
        {
          name: `SAP Certified Technology Associate - OS/DB Migration for SAP NetWeaver 7.52`,
          image: certificateImg,
        },
        {
          name: `SAP Certified Application Associate - SAP Mobile Platform Native and Hybrid Application Administration (SMP 2.3)`,
          image: certificateImg,
        },
        {
          name: `SAP Certified Support Associate - Incident Management with SAP BusinessObjects `,
          image: certificateImg,
        },
        {
          name: `SAP Certified Support Associate - SAP HANA`,
          image: certificateImg,
        },
        {
          name: `Support Consultant for Incident Management with SAP Business All-in-One`,
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Application - Finance & Controlling',
      certificates: [
        {
          name: 'Certyfikat Management Accounting (CO) SAP ERP',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Application Associate - Financial Accounting with SAP ERP 6.0 EhP7',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Application Associate - Financial Accounting with SAP ERP 6.0 EHP4',
          image: certificateImg,
        },
        { name: 'MySAP Financials – ERP', image: certificatePic },
        { name: 'Szkoła Controllingu V-IX 2000', image: certificatePic },
      ],
    },
    {
      section: 'Application - Logistics',
      certificates: [
        {
          name: 'SAP Certified Application Associate - Order Fulfillment with SAP ERP 6.0 EHP5',
          image: certificateImg,
        },
        {
          name: 'SAP Solution Consultant SCM - Order Fulfillment with mySAP ERP',
          image: certificateImg,
        },
        {
          name: 'Application Consultant ‘Sales and Distribution’ with mySAP.com. Certification2000',
          image: certificateImg,
        },
        {
          name: 'Solution Consultant SCM - Procurement with my SAP ERP',
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Application - Human Capital Management',
      certificates: [
        {
          name: 'SAP Certified Application Associate - Human Capital Management with SAP ERP 6.0 EHP5',
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Integration & Development',
      certificates: [
        {
          name: 'SAP Certified Development Associate - Process Integration with SAP NetWeaver (PI 7.3)',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Development Associate - ABAP with SAP NetWeaver 7.50',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Development Consultant SAP NetWeaver 2004 - Application Development Focus ABAP',
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Project Management',
      certificates: [
        {
          name: 'Prince2 Foundation (APM Group) 2012',
          image: certificatePrince,
        },
        {
          name: 'Prince2 Practitioner (APM Group) 2011',
          image: certificatePrince,
        },
      ],
    },
  ];

  const TEAM_MEMBERS = useMemo(
    () =>
      teamMembersEN.map(member => ({
        name: member.name,
        surname: member.surname,
        slug: slugify(`${member.name}-${member.surname}`, {
          lower: true,
        }),
        img: data[`teamMember${member.id}`].childImageSharp.fluid,
        linkedin: member.linkedin,
        role: member.role,
      })),
    []
  );

  return (
    <>
      <SEO title="Startseite" />
      <div id="home" />
      <Header
        image={heroImg}
        imageAlt="banner image"
        slides={slides}
        buttonText="Mehr über die Offerte"
      />
      <main>
        <About
          heading="Über <b>uns</b>"
          cards={cards}
          description1="Unseres Team bilden erfahrene Berater, Programmierer und Projektleiter. Die Erfahrung, Professionalität und Effektivität im Einsatz – auf diese Weise kann man unsere Kompetenzen beschreiben. Dank der langjährigen Erfahrung bei der SAP-Implementierung haben wir zahlreiche know-how gebildet. Jetzt können wir umfassende Lösungen, die auf alle Bedürfnisse unserer Kunden aus jeder Barche zugeschnitten sind, anbieten."
          description2="Die erfolgreiche Implementierung von ERP-Systemen erfordert von uns nicht nur umfangreiche System- und Konfigurationskenntnisse aber auch und vor allem eine fundierte Prozesskentnisse, die mit den implementierten Funktionalitäten verbunden sind - wie Rechnungswesen, Finanzen, Controlling, Logistik oder Produktion.<br/><br/>Wir können auch die Dienstleistungen einer Gruppe von sehr erfahrenen ABAP-Programmierern anbieten.<br/><br/>Zusätzlich zu den auf langjähriger Erfahrung mit SAP ERP- und SAP S/4 HANA-Lösungen basierenden Kenntnissen, verfügen alle unsere Berater SAP-Zertifikate in den von ihnen betreuten Bereichen."
        />
        <Competences
          heading="Unser <b>Erfahrungsschatz</b>"
          cards={competencesCards}
        />
        <OurTeam
          heading="Treffen Sie <b> unser Team</b>"
          members={TEAM_MEMBERS}
        />
        <Certificates heading="Zertifikate" sections={certSections} />
        <WhySAP
          heading="Warum <b>SAP?</b>"
          description="Um seine Strategie wirksam umsetzen, muss jedes Unternehmen entschprechende Instrumente, die es bei der Erreichung seiner Ziele unterstützen können, haben. Zu solchen Instrumenten gehören moderne IT-Systeme von SAP A.G. Diese eröffnen ganz neue Möglichkeiten, unetrstützen und ermöglichen richtige Unternehmensentscheidungen zu treffen und die Effektivität und Effizienz der Abläufe zu steigern und letztendlich Betriebskosten erheblich zu senken.<br/><br/>
Die einfache und intuitive Bedienung, die Rationalisierung des Informationsaustauschs und die Automatisierung sich wiederholender Tätigkeiten sind wiederum Merkmale, die von den Mitarbeitern, die das System nutzen, sehr geschätzt werden.
Die Systembenutzer schätzen einfache und intuitive Systembedienung, die Automatisierung sich wiederholenden Vorgänge und  die Verbesserung und Optimierung des Informationsaustauschs.
Während SAP A.G. als der Marktführer im Bereich der Unternehmensmanagement – Sytemen gilt, ist SAP S/4 HANA die unangefochtene Nummer eins.
Solche Popularität verdankt es seinem enormen Funktionsumfang, seiner Konfiguration-Flexibilität und moderner Technologie.
Die Anwender der SAP ERP- und SAP S/4 HANA-Systemen betonen die Sicherheit, große Integrationsmöglichkeiten und Interoperabilität mit anderen Systemen sowie die Flexibilität und Skalierbarkeit.
"
        />
        <Offer
          heading="Die Offerte – was können wir <b>fuer Sie</b> machen?"
          cards={offerCards}
        />
        {jobOffers.length && <Career heading="Jobangebote" cards={jobOffers} />}

        <Contact
          header={{
            title: 'Mission',
            description:
              'Unsere Mission ist es, Dienstleistungen von höchster Qualität im Bereich der Implementierung, Entwicklung, Innovation und Wartung des SAP ERP-Systems zu erbringen.',
          }}
          contactHeading="Kontaktiere <b>uns</b>"
          contactData="<b>Aseti Sp. z o.o.</b><br/><br/>Marynarska St.<br/>02-674 Warschau<br/><br/>NIP 5252821667<br/>REGON 385964076"
          contactEmail="biuro@aseti.pl"
        />
      </main>
    </>
  );
};

export const query = graphql`
  {
    heroImg: file(name: { eq: "hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    card1Img: file(name: { eq: "card3" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    card2Img: file(name: { eq: "card1" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    card3Img: file(name: { eq: "card2" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificateImg: file(name: { eq: "certificate" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificatePicImg: file(name: { eq: "certificate-pic" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificatePrinceImg: file(name: { eq: "certificate-prince" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember1: file(name: { eq: "team-member-1" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember2: file(name: { eq: "team-member-2" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember3: file(name: { eq: "team-member-3" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember4: file(name: { eq: "team-member-4" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember5: file(name: { eq: "team-member-5" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember6: file(name: { eq: "team-member-6" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember7: file(name: { eq: "team-member-7" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember8: file(name: { eq: "team-member-8" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember9: file(name: { eq: "team-member-9" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember10: file(name: { eq: "team-member-10" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    userIcon: file(name: { eq: "user" }) {
      publicURL
    }
    likeIcon: file(name: { eq: "like" }) {
      publicURL
    }
    groupIcon: file(name: { eq: "group" }) {
      publicURL
    }
    internetIcon: file(name: { eq: "internet" }) {
      publicURL
    }
    workIcon: file(name: { eq: "work-shedule" }) {
      publicURL
    }
    clockIcon: file(name: { eq: "wall-clock" }) {
      publicURL
    }
    laptopIcon: file(name: { eq: "laptop" }) {
      publicURL
    }
    serverIcon: file(name: { eq: "server" }) {
      publicURL
    }
    competence1Icon: file(name: { eq: "competence1" }) {
      publicURL
    }
    competence2Icon: file(name: { eq: "competence2" }) {
      publicURL
    }
    competence3Icon: file(name: { eq: "competence3" }) {
      publicURL
    }
    competence4Icon: file(name: { eq: "competence4" }) {
      publicURL
    }
    competence5Icon: file(name: { eq: "competence5" }) {
      publicURL
    }
    allWpPost {
      edges {
        node {
          jobOffer {
            jobCity
            jobOfferEnding
            jobOfferIntro
            jobOfferPosition
            jobPosition
            jobOfferNicetosee {
              jobOfferNicetoseeSingle
            }
            jobOfferOuroffer {
              jobOfferOurofferSingle
            }
            jobOfferRequirements {
              jobOfferRequirementsSingle
            }
            jobOfferResponsibilities {
              jobOfferResponsibilitesSingle
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default IndexPage;
